import React from "react"
import legalSidebarStyles from "./legalSidebar.module.scss"
import config from '../../../utils/siteConfig'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const LegalSidebar = ({ data, intl }) => (
  
    
  <ul className={legalSidebarStyles.legalSidebar}>
    <li><a href={config.navigationURL_legal_imprint}><FormattedMessage id="lg_I"/></a></li>
    <li><a href={config.navigationURL_legal_cookie_policy}><FormattedMessage id="lg_CP"/></a></li>
    <li><a href={config.navigationURL_legal_data_protection}><FormattedMessage id="lg_D"/></a></li>            
    <li><a href={config.navigationURL_legal_subscription_conditions}><FormattedMessage id="lg_S"/></a></li>
    <li><a href={config.navigationURL_legal_license_terms}><FormattedMessage id="lg_L"/></a></li>
    <li><a href={config.navigationURL_legal_terms_and_conditions}><FormattedMessage id="lg_T"/></a></li>
    <li><a href={config.navigationURL_legal_requirements}><FormattedMessage id="lg_R"/></a></li>
    <li><a href={config.navigationURL_legal_compliance}><FormattedMessage id="lg_C"/></a></li>
  </ul>

  
  // <ul className={legalSidebarStyles.legalSidebar}>
  //   <li><a href={config.navigationURL_legal_imprint}>Imprint</a></li>
  //   <li><a href={config.navigationURL_legal_cookie_policy}>Cookie Policy</a></li>
  //   <li><a href={config.navigationURL_legal_data_protection}>Data protection</a></li>            
  //   <li><a href={config.navigationURL_legal_subscription_conditions}>Subscription conditions</a></li>
  //   <li><a href={config.navigationURL_legal_license_terms}>License terms</a></li>
  //   <li><a href={config.navigationURL_legal_terms_and_conditions}>Terms and conditions</a></li>
  //   <li><a href={config.navigationURL_legal_requirements}>Requirements</a></li>
  //   <li><a href={config.navigationURL_legal_compliance}>Compliance</a></li>
  // </ul>

)

export default injectIntl(LegalSidebar)

// import FooterNavItem from "../../Molecules/FooterNavItem/FooterNavItem"

//import { useQuery, useSubscription } from 'urql';
// import { StaticQuery, graphql } from "gatsby";
// // import gql from 'graphql-tag';

// //const FEED_QUERY = gql` 
// const FEED_QUERY = graphql`
// query($locale: String!){
//         allContentfulLegalPages(filter: { node_locale: {eq: $locale}}) {
//           edges {
//             node {
//               metaData {
//                 title
//                 slug
//               }
//               heading
//               body {
//                 body
//                 json
//               }
//             }
//           }
//         }
//       }
  
// `


// const LegalSidebar = () => {
//   debugger;
//   const [result] = useQuery({ query: FEED_QUERY })
//   const { data} = result



  
  
//   const legalPages = data.allContentfulLegalPages.edges

//   return (
//     // <div>
//     //   {linksToRender.map(link => <Link key={link.id} link={link} />)}
//     // </div>
//   // <ul className={legalSidebarStyles.legalSidebar}>
//   //   <li><a href={config.navigationURL_legal_imprint}>Imprint</a></li>
//   // </ul>
//   <ul className={legalSidebarStyles.legalSidebar}>
//     {legalPages.map(legalPage => 
//         <li><a href={legalPage.node.metaData.slug}>{legalPage.node.heading}</a></li>
//     )}
//   </ul>

//   )
// }


// export default LegalSidebar





// const LegalSidebar = props => {
//   return (
//     <StaticQuery
//       query={graphql`
//         query($locale: String!){
//           allContentfulLegalPages(filter: {metaData: {slug: {eq: "legal-cookie-policy"}}, node_locale: {eq: $locale}}) {
//             edges {



//               node {
//                 metaData {
//                   title
//                   slug
//                 }
//                 heading
//                 body {
//                   body
//                   json
//                 }
//               }
//             }
//           }
//         }
//       `}
//       render={data => 
//           (
//           <ul className={legalSidebarStyles.legalSidebar}>
//             {data.allContentfulLegalPages.edges.map(legalPage => 
//               <li><a href={legalPage.node.metaData.slug}>{legalPage.node.heading}</a></li>
//             )}

//           </ul>
//           )
//       }
//     />
//   );
// };

// export default LegalSidebar;


// const LegalSidebar = () => {
//   const [result] = useQuery({ query: FEED_QUERY })
//   const { data} = result
  
  
//   const linksToRender = data.feed.links

//   return (
//     // <div>
//     //   {linksToRender.map(link => <Link key={link.id} link={link} />)}
//     // </div>
//   <ul className={legalSidebarStyles.legalSidebar}>
//     <li><a href={config.navigationURL_legal_imprint}>Imprint</a></li>
//   </ul>

//   )
// }


// export default LegalSidebar










  // <div className={footerStyles.footer}>
  //   <div className={footerStyles.footerWrapper}>
  //     <div>
  //         <ul className={footerStyles.footerWrapperUl}>
  //           <li className={footerStyles.footerWrapperUlLi}>
  //             <div className={footerStyles.footerLogoCol}>
  //               <ul className={footerStyles.footerWrapperUlStyleNone}>
  //                 <div className={footerStyles.footerLogo}>
  //                   <a className={footerStyles.footerLogoFont} href="/">
  //                   <img src={require('../../../images/Logo/Wunderloop_logo_10.2020-icon-pink.svg')} className="img-fluid img-Logo" alt="image"/>
  //                     Wunderloop
  //                   </a>                    
                    
  //                 </div>
  //               </ul>
  //             </div>
  //           </li>             
  //         </ul>

  //         <ul className={footerStyles.footerWrapperUl}>
  //           <li className={footerStyles.footerWrapperUlLi}>
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>
  //               <div className={footerStyles.white}>&nbsp;</div>
  //             </ul>   
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>
  //              <FooterNavItem  href={config.navigationURL_home} 
  //                         rel="nofollow noopener noreferrer"
  //                         target="">Home</FooterNavItem>
  //             <FooterNavItem  href={config.navigationURL_resourceCenter}
  //                       rel="nofollow noopener noreferrer"
  //                       target="">Resources</FooterNavItem>
  //             </ul>
  //           </li>

  //           <li className={footerStyles.footerWrapperUlLi}>
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>
  //               <a href={config.navigationURL_apps} className={footerStyles.white}>Apps Overview</a>
  //             </ul> 
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>

  //             </ul>
  //           </li>



  //           <li className={footerStyles.footerWrapperUlLi}>
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>
  //               <div className={footerStyles.white}>Company</div>
  //             </ul>   
  //             <ul className={footerStyles.footerWrapperUlStyleNone}>
                
  //             <FooterNavItem  href={config.navigationURL_contactUs}
  //                       rel="nofollow noopener noreferrer"
  //                       target="">Contact Us</FooterNavItem>
  //             <FooterNavItem  href={config.navigationURL_aboutUs}
  //                        rel="nofollow noopener noreferrer"
  //                        target="">About Us</FooterNavItem>
  //             <FooterNavItem  href={config.navigationURL_legal_imprint} //href={props.toButton}
  //                       rel="nofollow noopener noreferrer"
  //                       target="">Imprint</FooterNavItem>

  //             </ul>
  //           </li>

  //         </ul>

  //       </div>

  //     </div>
  //   </div>
